<template>
  <div></div>
</template>

<script>
import store from '../store'
import { mapState } from 'vuex'
import AuthService from '@/api/AuthService'

export default {
  computed: {
    ...mapState({
      code: state => state?.app?.code
    })
  },
  async created() {
    await AuthService.authManager()
    const code = this.$route.params?.hash
    if (code && code !== this.code && code !== '*') {
      await store.dispatch('app/SET_APP_CODE_ACTION', {
        code: code,
        toMain: true,
        isDesktop: true,
        notGetOrderPageConfig: true,
        notGetSiteBurger: true
      })
    }
  }
}
</script>
